import React, { useState, useEffect } from "react";
import { Plan, pricingPlans } from "data/pricingData";
import ModalFeatures from "./ModalFeatures";

type PlanProps = {
  plan: Plan;
};

const PricingPlan: React.FC<PlanProps> = React.memo(({ plan }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    document.body.style.overflow = isModalOpen ? "hidden" : "auto";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isModalOpen]);

  return (
    <div className="plan">
      <div className="plan-head">
        <p className="plan-name">{plan.name}</p>
        <p className="plan-price">{plan.price}</p>
        <p className="plan-description">{plan.description}</p>
        <a
          href={`https://app.skulabs.com/signup?plan=${plan.code}`}
          className="cta"
          target="_blank"
          rel="noopener noreferrer"
        >
          Choose this plan
        </a>
      </div>
      <div className="plan-body">
        {plan.mainFeatures.map((feature) => (
          <div
            key={feature.name}
            data-rowname={feature.name}
            className="plan-mainfeature"
          >
            <p className="plan-feature">
              <img
                src={feature.icon}
                alt={feature.name}
                width={24}
                height={24}
              />
              {feature.name}
            </p>
            <p className="plan-value">
              {feature.limit}
              {feature.additionalCost && (
                <>
                  <span className="plan-additional">
                    {feature.additionalCost}
                  </span>
                  <span className="plan-additional-explanation">
                    Additional {feature.name}
                  </span>
                </>
              )}
            </p>
          </div>
        ))}
        <button className="cta more-features" onClick={openModal}>
          All Features
        </button>
      </div>

      {isModalOpen && (
        <ModalFeatures
          planName={plan.name.toLowerCase() as keyof typeof pricingPlans}
          onClose={closeModal}
        />
      )}
    </div>
  );
});

export default PricingPlan;
