import React, { useEffect, useState } from "react";
import { CalendlyWrapper } from "components/Layouts/CalendlyWrapper/CalendlyWrapper";
import LiveDemoForm from "components/Layouts/LiveDemoForm/LiveDemoForm";
import SupportSection from "components/Layouts/Support/SupportSection";

export const Talk = () => {
  const [calendlyUrl, setCalendlyUrl] = useState("");

  useEffect(() => {
    const today = new Date();
    let year = today.getFullYear();
    let month = String(today.getMonth() + 1).padStart(2, "0");
    let day = String(today.getDate()).padStart(2, "0");

    const currentHour = today.getHours();
    const currentMinutes = today.getMinutes();

    if (currentHour > 16 || (currentHour === 16 && currentMinutes > 50)) {
      const tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1);
      year = tomorrow.getFullYear();
      month = String(tomorrow.getMonth() + 1).padStart(2, "0");
      day = String(tomorrow.getDate()).padStart(2, "0");
    }

    const url = `https://calendly.com/skulabs/sales?hide_event_type_details=1&background_color=f9f9f9&text_color=333333&primary_color=4aa199`;
    setCalendlyUrl(url);
  }, []);

  return (
    <>
      <div className="demo-head page-head container">
        <h1>Let’s Talk—We’re Ready to Assist You!</h1>
        <p className="description">
          Have questions or need assistance? Feel free to give us a call or drop
          us an email. <br /> We're always happy to help!
        </p>
        <div className="cta-container">
          <a href="tel: +18002432986" className="cta">
            Talk us at: +1 (800) 243-2986
          </a>
          <a href="mailto:sales@skulabs.com" className="cta-border">
            sales@skulabs.com
          </a>
        </div>
        <div className="calendary-head">
          <h2 className="title">
            Need Assistance?
            <br /> Schedule a Call with Our Support Team Now!
          </h2>
        </div>

        {calendlyUrl && <CalendlyWrapper src={calendlyUrl} />}
        <div className="divider-or">
          <hr />
          or
          <hr />
        </div>
        <h2 className="title">Schedule Your Personalized Demo Today!</h2>
        <p>
          Enter your email to connect with our support team and explore how we
          can help you achieve your goals with a live demo tailored just for you
        </p>
        <LiveDemoForm />
      </div>
    </>
  );
};

export default Talk;
