import { Link } from "react-router-dom";
import supportData from "data/supportData";

const SupportSection = () => {
  return (
    <section className="support">
      <div className="container">
        <h2>Customer support like you’ve never experienced before</h2>
        <div className="support-items">
          {supportData.map((item) => (
            <div key={item.title} className="support-item">
              <img
                src={item.icon}
                alt=""
                width={128}
                height={128}
                loading="lazy"
              />
              <p className="title">{item.title}</p>
              <p className="description">{item.descriptiom}</p>
            </div>
          ))}
        </div>
        <div className="ctas">
          <Link to="/pricing" className="cta">
            Get started now
          </Link>
          <a href="/talk" className="cta-secondary">
            Live Demo
          </a>
        </div>
      </div>
    </section>
  );
};

export default SupportSection;
