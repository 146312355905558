import { pricingPlans, comparePlanData } from "data/pricingData";

const ComparePlans = () => {
  const planKeys = ["starter", "pro", "premium"];
  const planData = planKeys.map((key) => pricingPlans[key]);

  return (
    <div className="plans-compare">
      <h2>Compare Plans</h2>
      <div className="plans-features">
        <div className="plans-header">
          <div className="col">
            <h3>Features</h3>
          </div>
          {planData.map((plan, index) => (
            <div className="col" key={index}>
              <div className="plan-features-head">
                <p>
                  {plan.name} - <span>{plan.price}</span>
                </p>
                <a
                  href={`https://app.skulabs.com/signup?plan=${plan.code}`}
                  className="cta"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Choose this plan
                </a>
              </div>
            </div>
          ))}
        </div>
        {comparePlanData.map((category, catIndex) => (
          <div className="plans-category" key={catIndex}>
            <div className="plans-category-title">{category.title}</div>
            <div className="category-table">
              {category.features.map((feature, featIndex) => (
                <div className="row" key={featIndex}>
                  <div className="col feature-name">
                    {feature.description && (
                      <div className="more-feature-info">
                        <img
                          src="/icons/info-circle.svg"
                          alt="info"
                          width={24}
                          height={24}
                        />
                        <p>{feature.description}</p>
                      </div>
                    )}
                    <span>{feature.name}</span>
                  </div>
                  {planKeys.map((planKey, planIndex) => (
                    <div className="col" key={planIndex}>
                      {feature.plans[planKey] ? (
                        <img
                          src="/icons/check.svg"
                          alt="check"
                          width={15}
                          height={11}
                        />
                      ) : (
                        <img
                          src="/icons/dot.svg"
                          alt="dot"
                          width={12}
                          height={12}
                        />
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ComparePlans;
